export const getTourName = (name: any, lang: string) => {
  if (String(name).charAt(0) === "[") {
    //eslint-disable-next-line
    return JSON.parse(name)?.find((item: any) => item.language === lang)?.text;
  } else {
    return name;
  }
};

export const getTourDescription = (description: any, lang: string) => {
  if (String(description).charAt(0) === "[") {
    //eslint-disable-next-line
    return JSON.parse(description)?.find((item: any) => item.language === lang)
      ?.text;
  } else {
    return description;
  }
};

export const getStringOrParsedJson = (data: any) => {
  if (String(data).charAt(0) === "[") {
    return JSON.parse(data);
  } else {
    const temp = [
      {
        text: data,
        language: "en",
      },
    ];
    return temp;
  }
};
