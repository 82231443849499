import { useEffect, useState } from "react";
import { ImgUrl } from "../../services/InstanceAxios";
import Images from "../../constant/Images";
import { useNavigate } from "react-router-dom";
import AddStopsComponent from "../../component/places/modal/AddStopsComponent";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/app/store";
import {
  getsingleTourSlice,
  setStopDetails,
} from "../../store/features/singleTourSlice";
import EditStopDetails from "../../component/places/modal/EditStopDetails";
import DeleteModalComponent from "../../component/places/modal/DeleteModalComponent";
import services from "../../services/services";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactQuill from "react-quill";
import StopDetails from "../stopDetails/StopDetails";
import { getTourDescription, getTourName } from "../../services/Tour";

const StopList = (bundle: any) => {
  const dispatch: any = useAppDispatch();
  const navigate = useNavigate();
  const tours = useSelector((state: any) => state.singleTourSlice);
  const [elm, setElm] = useState<any>({});
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showList, setShowList] = useState<any>({});
  const [tourDetails, setTourDetails] = useState<any[]>([]);
  const sortedTourDetails = tourDetails
    .slice()
    .sort((a: any, b: any) => a.id - b.id);
  const handleShowMore = (index: any) => {
    if (showList[index]) {
      setShowList({});
    } else {
      setShowList({
        [index]: true,
      });
    }
  };

  useEffect(() => {
    dispatch(getsingleTourSlice(tours?.tour?.id));
  }, []);

  useEffect(() => {
    if (tours?.data?.tourPointsDetail) {
      setTourDetails(tours?.data?.tourPointsDetail);
    }
  }, [tours?.data?.tourPointsDetail]);

  const handleNavigate = (data: any) => {
    if (data == "AddSaftyInformation") {
      navigate("/addSaftyInformation");
    } else {
      dispatch(setStopDetails(data));
      navigate("/stopDetails");
    }
  };
  const handleSaveData = async () => {
    const res: any = await services.editStopList(tourDetails);
    // Your logic to save the data goes here
    // Once the data is saved successfully, set showSuccessMessage to true
    toast.success("Stops saved successfully");
  };
  //dispatch(getsingleTourSlice(stop?.id));
  const deleteTour = async (id: any, tourId: any) => {
    const res: any = await services.deleteStop(id);
    if (res?.status == 200) {
      toast.dismiss();
      toast.success(res?.data.message);
      dispatch(getsingleTourSlice(tours?.tour?.id));
    } else {
      toast.error("SomeThing went wrong");
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    const updatedSortedTourDetails: any = [];
    // Move the data of elements based on the direction
    if (startIndex < endIndex) {
      for (let i = 0; i < startIndex; i++) {
        updatedSortedTourDetails.push(tourDetails[i]);
      }
      var temp = tourDetails[startIndex].id;
      var startElement = JSON.parse(JSON.stringify(tourDetails[startIndex]));
      startElement.id = tourDetails[endIndex].id;
      for (let i = startIndex + 1; i <= endIndex; i++) {
        const temp1 = tourDetails[i].id;
        var element = JSON.parse(JSON.stringify(tourDetails[i]));
        element.id = temp;
        temp = temp1;
        updatedSortedTourDetails.push(element); //tourDetail[i]
      }

      updatedSortedTourDetails.push(startElement); //tourDetail[i]
      for (let i = endIndex + 1; i < tourDetails.length; i++) {
        updatedSortedTourDetails.push(tourDetails[i]);
      }
    } else {
      for (let i = 0; i < endIndex; i++) {
        updatedSortedTourDetails.push(tourDetails[i]);
      }
      var startElement = JSON.parse(JSON.stringify(tourDetails[startIndex]));
      startElement.id = tourDetails[endIndex].id;
      updatedSortedTourDetails.push(startElement); //tourDetail[i]

      for (let i = endIndex; i < startIndex; i++) {
        var element = JSON.parse(JSON.stringify(tourDetails[i]));
        element.id = tourDetails[i + 1].id;
        updatedSortedTourDetails.push(element); //tourDetail[i]
      }

      for (let i = startIndex + 1; i < tourDetails.length; i++) {
        updatedSortedTourDetails.push(tourDetails[i]);
      }
    }
    // Update the state with the new sorted and updated elements
    setTourDetails(updatedSortedTourDetails);
  };

  const getdata = async () => {
    try {
      const res = await services.getSingleTourDirection({
        tourId: tours?.tour?.id,
      });
    } catch (err) {}
  };

  return (
    <div className="w-full px-12 py-6">
      <div className="flex  w-full justify-between items-center">
        <div className="w-2/4">
          <p className="text-blue font-roboto font-bold text-xl">
            {/* {tours?.tour?.name} */}
            {getTourName(tours?.tour?.name, "en")}
          </p>
        </div>
        <div className="flex w-3/4 justify-end mr-4">
          <AddStopsComponent Tour={tours?.tour} />

          <button
            className="bg-blue text-white rounded-full py-3.5 px-6 font-roboto font-normal xl:py-2 ml-4"
            onClick={handleNavigate.bind(this, "AddSaftyInformation")}
          >
            Add Saftey Information
          </button>
          <button
            className="bg-blue text-white rounded-full py-3.5 px-6 font-roboto font-normal xl:py-2 ml-4"
            type="button"
            onClick={async () => {
              handleSaveData();
              await services?.getTourSave({ tourId: tours?.tour?.id });
              getdata();
            }}
          >
            Save
          </button>
          <button
            className="bg-aqua text-white rounded-full py-3.5 px-6 font-roboto font-normal xl:py-2 ml-4 hover:bg-blue active:bg-blue"
            type="button"
            onClick={async () => {
              try {
                const res = await services?.updateOffline(tours?.tour?.id);
                toast.dismiss();
                toast.success("Updated successfully");
              } catch (error) {
                toast.dismiss();
                toast.error("Something went wrong");
                console.error(error);
              }
            }}
          >
            Update
          </button>
        </div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="">
          <Droppable droppableId="tourPoints">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {sortedTourDetails?.map((elm: any, index: number) => (
                  <Draggable
                    key={elm.id}
                    draggableId={elm.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="mt-10"
                      >
                        <div
                          className="mt-10 flex items-center border border-aqua rounded-b-2xl"
                          key={index}
                        >
                          <img
                            className="rounded-2xl h-[173px] mr-4"
                            src={
                              elm?.thumbnailUrl
                                ? ImgUrl +
                                  elm?.thumbnailUrl.replace(
                                    /\/root\/imageUpload\//g,
                                    ""
                                  )
                                : Images.berna
                            }
                            alt="Sunset in the mountains"
                          />
                          <div className="px-4 py-2.5 max-h-[200px] min-h-[200px] w-full flex flex-col justify-between">
                            <div className="flex justify-between w-full">
                              <p className="text-xl text-blue font-roboto font-bold w-[80%] break-words">
                                {getTourName(elm?.name, "en")}
                              </p>
                              <div className="flex">
                                <div>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setElm(elm);
                                      setShowEditModal(true);
                                    }}
                                  >
                                    <img src={Images.edit} alt="edit-icon" />
                                  </button>
                                </div>
                                <div>
                                  <DeleteModalComponent
                                    dlFn={() => {
                                      deleteTour(elm?.id, elm?.tourId);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <p
                              className={`text-black w-full font-ProximaNovaAltRgRegular ${
                                showList ? "h-auto" : "h-auto"
                              } font-normal text-lg xl:text-sm`}
                            >
                              <ReactQuill
                                modules={{
                                  toolbar: false,
                                }}
                                // value={elm?.discription || ""} // Provide a default value to handle possible undefined
                                value={getTourDescription(
                                  elm?.discription,
                                  "en"
                                )}
                                readOnly={true}
                              />
                            </p>
                            <p>Longitude: {elm?.longitude}</p>
                            <p>Latitude: {elm?.lattitude}</p>
                            <p>Radius: {elm?.radius}</p>
                            <div>
                              <button
                                className="bg-aqua text-white rounded-full py-3.5 px-9 font-roboto font-normal xl:py-2.5  ml-1"
                                onClick={handleNavigate.bind(this, elm)}
                              >
                                Learn more
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      {showEditModal ? (
        <EditStopDetails
          stop={elm}
          visible={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      ) : null}
    </div>
  );
};

export default StopList;
