import React, { useState } from "react";
import Images from "../../../constant/Images";
import InputComponent from "../../inputComponent/InputComponent";
import { addTourinput, createUserInput } from "../../../constant/string";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import SelectComponentUser from "../../selectComponentUser/SelectComponentUser";
import services from "../../../services/services";
import { toast } from "react-toastify";
import { getallUsersThank } from "../../../store/features/getUsersSlice";
import { useAppDispatch } from "../../../store/app/store";
import { getTourName } from "../../../services/Tour";

const CreateUserModal = () => {
  const places = useSelector((state: any) => state.placesList);
  const dispatch: any = useAppDispatch();
  const { Tours } = useSelector(
    (state: any) => state.tours
    );
  const [showModal, setShowModal] = React.useState(false);

  const filteredTours = Tours?.data?.data.map((tour: any) => {
      return {
          ...tour,
          name: getTourName(tour?.name, "en") || '' // If English name exists, assign its text, otherwise empty string
      };
  });

  const formik: any = useFormik({
    initialValues: {
      phone: "",
      name: "",
      email: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().trim(),
      email: Yup.string().email().trim(),
      phone: Yup.string().trim()
    }).test('either-email-or-phone', 'Either email or phone should be filled', function (values) {
      const { email, phone } = values;
      
      if (!email && !phone) {
        return this.createError({
          path: 'email',
          message: 'Either email or phone should be filled',
        });
      }
      return true;
    }),
    onSubmit: (values: any) => {
      const details = JSON.parse(formik.values.cartDetails);
      const cartDetails =[ {
        productId: details.productId,
        destinationName: details.name,
        imagesUrl: details.imageUrl,
        placeId: details.placeId,
        status: 1,
        amount: details.sellingPrice,
        tourId: details.id,
        bundelsId: 1,
    }]; 
    const payload = { ...formik.values, cartDetails}

    formik?.handleReset(formik.values);
    setShowModal(false);
    handleCreateUser(payload)
    },
  });

  const handleCreateUser = async (payload: any) => {
    try {
      const res = await services.createUserApi({...payload});
      if (res.status === true) {
        dispatch(getallUsersThank());
        toast.success("User Created!");
      }
    } catch (error) {
      toast.error("Error Creating User!");
      console.log(error);
    }
  };

  return (
    <>
      <button
        className="rounded-3xl bg-dimblack flex  justify-center items-center w-36 h-10 mt-6"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <img
          className=" h-3.5 w-3.5 mr-2 font-roboto font-medium text-xl"
          src={Images.add}
          alt=""
        />
        <p className=" text-white">Create User</p>
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none">
            <div className="relative w-[900px] h-[600px] bg-white my-6 rounded overflow-y-auto overflow-x-hidden">
              {/*content*/}

              {/*header*/}

              <div className="flex justify-between text-xl font-semibold bg-lightaqua w-[100%] h-[70px] items-center rounded">
                <div></div>
                <h3 className="font-sans font-bold">Create User</h3>
                <div className="bg-aquadark w-[70px] h-[70px] rounded-xl flex justify-center ">
                  <button
                    className="p-1"
                    onClick={() => {
                      formik.handleReset(formik.values);
                      setShowModal(false);
                    }}
                  >
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none "
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>

              {/*body*/}

              <form className="px-12 py-9" onSubmit={formik.handleSubmit}>
                <div className="">
                  {createUserInput.map((elm: any) =>
                    elm?.key == "option" ? (
                      <SelectComponentUser
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                        option={elm?.key1 == "tourId" ? filteredTours : elm?.option}
                      />
                    ) : (
                      <InputComponent
                        id={elm?.name}
                        handlechange={ formik.handleChange
                        }
                        accept={elm?.accept}
                        value={
                          elm?.type == "file"
                            ? null
                            : (formik as any)?.values?.[elm?.name]
                        }
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    )
                  )}
                  <div>{/* <AddStopsComponent />{" "} */}</div>
                </div>

                <div className="mt-9">
                  <button
                    className="w-[150px] h-[50px] border rounded border-aqua  text-aqua"
                    type="button"
                    onClick={() => {
                      formik.handleReset(formik.values);
                      setShowModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6"
                    type="submit"
                    onClick={() => {}}
                  >
                    Save
                  </button>
                </div>
              </form>
              {/*footer*/}
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default CreateUserModal;
