import Images from "./Images";
export const sideBarMenu = [
  {
    link: "/dashboard",
    text: "Dashboard",
  },
  {
    link: "/users",
    text: "Users",
  },
  {
    link: "/places",
    text: "Places",
  },
  {
    link: "/tours",
    text: "Tours",
  },
  {
    link: "/bundles",
    text: "Bundles",
  },
  {
    link: "/bookings",
    text: "Bookings",
  },
  {
    link: "/admin",
    text: "Admin",
  },
  {
    link: "/pages",
    text: "Pages",
  },
  {
    link:"setting",
    text:"Settings",
  },
  {
    link:"",
    text:"Logout",
  }
];
export const cardList = [
  {
    id: 1,
    title: "Total Earn",
    total: "2561K",
    // year: "17% (2022-2023)",
    name: "TotalRevenue",
    image: Images.circle,
  },
  {
    id: 1,
    title: "Sold Tours",
    total: "354",
    name: "SoldTours",
    // year: "17% (2022-2023)",
  },
  {
    id: 1,
    title: "Cancelled Tours",
    total: "12",
    name: "cancelTour",
    // year: "17% (2022-2023)",
  },
  {
    id: 1,
    title: "Average Cost",
    total: "1254K",
    name: "average",
    // year: "17% (2022-2023)",
  },
  {
    id: 1,
    title: "Total User",
    total: "1254K",
    name: "TotalUser",
    // year: "17% (2022-2023)",
  },
];
export const dashBoradTableHeader = [
  {
    label: "Destination",
    key: "",
  },
  {
    label: "Sold",
    key: "",
  },
  // {
  //   label: "Growth",
  //   key: "",
  // },
  {
    label: "Earn",
    key: "",
  },
  {
    label: "Category",
    key: "",
  },
];
export const dashBoradTableBodyData = [
  {
    label: "Destination",
    key: "",
  },
  {
    label: "Sold",
    key: "",
  },
  {
    label: "Growth",
    key: "",
  },
  {
    label: "Earn",
    key: "",
  },
  {
    label: "Category",
    key: "",
  },
];
export const addTourinput = [
  {
    name: "name",
    label: "Tour Name*",
    type: "text",
    placeHolder: "Enter tour name",
  },
  {
    name: "bookingEmailTemplate",
    label: "Email*",
    type: "email",
    placeHolder: "Enter html content...",
  },
  {
    name: "productId",
    label: "Product Id (PlayStore or AppStore)*",
    type: "text",
    placeHolder: "Product id",
    value: "",
  },
  {
    name: "placeId",
    key: "option",
    key1: "placeId",
    label: "Add  Place*",
    option: [
      {
        name: "Driving",
        id: 1,
      },
      {
        name: "Walking",
        id: 2,
      },
    ],
  },
  {
    name: "published",
    key: "option",
    key1: "published",
    label: "Published",
    option: [
      {
        name: "True",
        id: 1,
      },
      {
        name: "False",
        id: 0,
      },
    ],
  },

  {
    name: "imageUrl",
    label: "Upload Image* ",
    accept: "image",
    type: "file",
    placeHolder: "Upload image",
  },
  {
    name: "thumbnailUrl",
    accept: "image",
    label: "Thumbnail Image*",
    type: "file",
    placeHolder: "Upload image",
  },
  // {
  //   name: "audioUrl",
  //   label: "Add Audio",
  //   accept: "audio",
  //   type: "file",
  //   placeHolder: "Upload Audio url",
  // },
  {
    name: "videoUrl",
    label: "Add Video*",
    type: "text",
    placeHolder: "Upload youTube video url",
  },
  {
    name: "type",
    key: "option",
    label: "Type*",
    option: [
      {
        name: "Driving",
        id: 2,
      },
      {
        name: "Walking",
        id: 1,
      },
    ],
  },
  {
    name: "duration",
    label: "Duration*",
    type: "text",
    placeHolder: "Enter duration",
  },
  // {
  //   name: "duration",
  //   label: "St",
  //   type: "number",
  //   placeHolder: "Enter duration",
  // },
  {
    name: "description",
    label: "Description*",
    type: "description",
    placeHolder: "Works offline",
  },

  {
    name: "title",
    label: "Title*",
    type: "text",
    placeHolder: "Enter title",
  },
  {
    name: "retailPrice",
    label: "Retail Price*",
    type: "text",
    placeHolder: "Retail price",
  },
  {
    name: "sellingPrice",
    label: "Selling Price",
    type: "text",
    placeHolder: "Selling price",
  },

  {
    name: "distance",
    // key: "option",
    label: "Distance*",
    type: "text",
  },
  {
    name: "reviewCount",
    label: "Add Review, add count*",
    type: "text",
    placeHolder: "Enter review",
  },

  {
    name: "carRentalPerPerson",
    label: "Car Per Person*",
    type: "text",
    placeHolder: "Per person",
  },

  // {
  //   name: "workOffline",
  //   label: "Works Offline",
  //   type: "text",
  //   placeHolder: "Selling Price",
  // },
  {
    name: "narrationPoints",
    label: "Narration Point*",
    type: "number",
    placeHolder: "Enter narration point",
  },
  {
    name: "bokunId",
    label: "Bokun Product Id",
    type: "number",
    placeHolder: "Bokun Product Id"
  },
];
export const addPlaceinput = [
  {
    name: "name",
    label: "Place Name*",
    type: "text",
    placeHolder: "Enter place name",
  },
  // {
  //   name: "audioUrl",
  //   label: "Add Audio",
  //   accept: "audio",
  //   type: "file",
  //   placeHolder: "Add audio url",
  // },
  // {
  //   name: "videoUrl",
  //   label: "Add Video",
  //   type: "text",
  //   placeHolder: "Add  YouTube video url",
  // },
  // {
  //   name: "description",
  //   label: "Description",
  //   type: "text",
  //   placeHolder: "Add discription",
  // },
  // {
  //   name: "websiteUrl",
  //   label: "Website URL",
  //   type: "text",
  //   placeHolder: "Website url",
  // },
  // {
  //   name: "status",
  //   key: "option",
  //   label: "Status",
  //   type: "text",
  //   placeHolder: "Enter place name",
  //   option: ["Driving", "Walking"],
  // },
  {
    name: "thumbnailUrl",
    label: "Upload Image*",
    accept: "image",
    type: "file",
    placeHolder: "Upload image",
  },
  {
    name: "published",
    key: "option",
    key1: "published",
    label: "Published*",
    option: [
      {
        name: "True",
        id: 1,
      },
      {
        name: "False",
        id: 0,
      },
    ],
  },
];
export const EditPlaceinput = [
  {
    name: "name",
    label: "Place Name*",
    type: "text",
    placeHolder: "Enter place name",
  },
  // {
  //   name: "audioUrl",
  //   label: "Add Audio",
  //   accept: "audio",
  //   type: "file",
  //   placeHolder: "Add audio url",
  // },
  {
    name: "videoUrl",
    label: "Add Video*",
    type: "text",
    placeHolder: "Add  youTube video url",
  },
  {
    name: "description",
    label: "Description*",
    type: "text",
    placeHolder: "Add discription",
  },
  {
    name: "websiteUrl",
    label: "Website URL*",
    type: "text",
    placeHolder: "Website url",
  },
  // {
  //   name: "status",
  //   key: "option",
  //   label: "Status",
  //   type: "text",
  //   placeHolder: "Enter place name",
  //   option: ["Driving", "Walking"],
  // },
  // {
  //   name: "thumbnailUrl",
  //   label: "Upload Image ",
  //   accept: "image",
  //   type: "file",
  //   placeHolder: "Upload Image",
  // },
];
export const EditFaqinput = [
  {
    name: "name",
    label: "Filter Name*",
    type: "text",
    placeHolder: "Enter filter name",
  },
  {
    name: "tourId",
    key: "option",
    key1: "tourId",
    label: "Add  Tour*",
  },
  {
    name: "topQuestion",
    label: "Add Question*",
    type: "text",
    placeHolder: "Add question",
  },
  {
    name: "description",
    label: "Add Answer*",
    type: "text",
    placeHolder: "Add answer",
  },
];
export const addStopinput = [
  {
    name: "name",
    label: "Stops Name*",
    type: "text",
    placeHolder: "Enter stop name",
  },
  {
    name: "discription",
    label: "Description*",
    type: "description",
    placeHolder: "Add discription",
  },
  {
    name: "thumbnailUrl",
    label: "Stops Image Upload*",
    type: "file",
    accept: "image",
    placeHolder: "Upload image url",
  },
  // {
  //   name: "audioUrl",
  //   label: "Add Preview Audio*",
  //   type: "file",
  //   accept: "audio",
  //   placeHolder: "Add audio url",
  // },
  // {
  //   name: "mainAudioUrl",
  //   label: "Add Main Audio*",
  //   type: "file",
  //   accept: "audio",
  //   placeHolder: "Add main audio url",
  // },
  {
    name: "stopLanguagesPreviewMap",
    label: "Add Preview Audio*",
    type: "language",
    accept: "audio",
    placeHolder: "Add preview audio url",
  },
  {
    name: "stopLanguagesMap",
    label: "Add Language Audio*",
    type: "language",
    accept: "audio",
    placeHolder: "Add language audio url",
  },
  {
    name: "audioLock",
    key: "option",
    label: "Lock Language Audio*",
    type: "text",
    option: [
      {
        name: "Locked",
        id: 1,
      },
      {
        name: "Unlocked",
        id: 0,
      },
    ],
  },
  // {
  //   name: "addVideo",
  //   label: "Add Video",
  //   type: "file",
  //   placeHolder: "Add audio url",
  // },
  {
    name: "longitude",
    label: "Longitude*",
    type: "text",
    placeHolder: "Longitude",
  },
  {
    name: "lattitude",
    label: "Latitude*",
    type: "text",
    placeHolder: "Latitude",
  },
  {
    name: "radius",
    label: "Radius*",
    type: "text",
    placeHolder: "Radius",
  },
  {
    name: "isHideStop",
    key: "option",
    label: "Hide Stop*",
    type: "text",
    option: [
      {
        name: "Yes",
        id: 1,
      },
      {
        name: "No",
        id: 0,
      },
    ],
  },
];
export const addMoreStopinput = [
  {
    name: "name",
    label: "Stops Name*",
    type: "text",
    placeHolder: "Enter stop name",
  },
  {
    name: "thumbnailUrl",
    label: "Stops Image Upload*",
    type: "file",
    placeHolder: "Upload image url",
  },
  {
    name: "addAudio",
    label: "Add Audio*",
    type: "file",
    placeHolder: "Add audio url",
  },
  {
    name: "addVideo",
    label: "Add Video*",
    type: "file",
    placeHolder: "Add audio url",
  },
  {
    name: "longitude",
    label: "Longitude*",
    type: "text",
    placeHolder: "Longitude",
  },
  {
    name: "lattitude",
    label: "Latitude*",
    type: "text",
    placeHolder: "Latitude",
  },
  {
    name: "radius",
    label: "Radius*",
    type: "text",
    placeHolder: "Radius",
  },
];
export const addFaq = [
  {
    name: "filterName",
    placeHolder: "Faq filter name",
    text: "text",
    label: "Filter Name*",
  },
  {
    name: "tourId",
    key: "option",
    key1: "tourId",
    label: "Add  Tour*",
  },
  {
    name: "addQuestion",
    placeHolder: "Add question",
    type: "text",
    label: "Add Question*",
  },
  {
    name: "addAnswer",
    placeHolder: "Add answer",
    text: "text",
    label: "Add Answer*",
  },
];
export const addReview = [
  {
    name: "reviewTitle",
    placeHolder: "Write your review heading here",
    text: "text",
    label: "Title*",
  },
  {
    name: "tourId",
    key: "option",
    key1: "tourId",
    label: "Add  Tour*",
  },
  {
    name: "review",
    placeHolder: "Review",
    type: "text",
    label: "Review*",
  },
  {
    name: "rating",
    placeHolder: "Ratings",
    text: "number",
    type: "number",
    label: "Ratings*",
  },
];
export const editReview = [
  {
    name: "reviewTitle",
    placeHolder: "Write your review heading here",
    text: "text",
    label: "Title*",
  },
  {
    name: "tourId",
    key: "option",
    key1: "tourId",
    label: "Add  Tour*",
  },
  {
    name: "review",
    placeHolder: "Review",
    type: "text",
    label: "Review*",
  },
  {
    name: "rating",
    placeHolder: "Ratings",
    text: "number",
    type: "number",
    label: "Ratings*",
  },
];
export const adminFunctions = [
  {
    name: "Add Tour",
    value: "tours",
  },
  {
    name: "Create Bundle",
    value: "bundels",
  },
  {
    name: "Edit User",
    value: "editUser",
  },
  {
    name: "Add Place",
    value: "places",
  },
  // {
  //   name: "Users",
  //   value: "users",
  // },
  {
    name: "Admin",
    value: "admin",
  },
  {
    name: "Pages",
    value: "pages",
  },
];
export const bundlesInput = [
  {
    name: "name",
    label: "Bundle Name*",
    type: "text",
    placeHolder: "Enter stop name",
  },
  {
    name: "productId",
    label: "Product Id (PlayStore or AppStore)*",
    type: "text",
    placeHolder: "Product id",
  },
  {
    name: "imageUrl",
    label: "Image Upload*",
    type: "file",
    accept: "image",
    placeHolder: "Upload image url",
  },

  {
    name: "retailPrice",
    label: "Retail Price*",
    type: "number",
    placeHolder: "Add retail price",
  },
  {
    name: "sellingPrice",
    label: "Selling Price",
    type: "text",
    placeHolder: "Add selling price",
  },
  {
    name: "description",
    label: "Description*",
    type: "text",
    placeHolder: "Add description",
  },
  // {
  //   name: "bundelsDetails",
  //   label: "Bundels Details",
  //   type: "text",
  //   placeHolder: "Bundels Details",
  // },
  // {
  //   name: "tour",
  //   key: "option",
  //   label: "Tour",
  //   type: "text",
  //   placeHolder: "Enter place name",
  //   option: ["Driving", "Walking"],
  // },
];
export const userEdit = [
  {
    name: "firstName",
    type: "text",
    placeHolder: "Name",
    label: "User Name*",
  },
  {
    name: "email",
    placeHolder: "Email",
    type: "email",
    label: "Email*",
  },
  {
    name: "contact",
    placeHolder: "Mobile number",
    type: "text",
    label: "Mobile Number*",
  },
];
export const loginUser = [
  {
    type: "text",
    name: "email",
    text: "email",
    label: "Email*",
    placeholder: "Enter your email",
  },
  {
    type: "password",
    name: "password",
    text: "text",
    label: "Password*",
    placeholder: "Enter your password",
  },
];
export const articleInput = [
  {
    name: "topicId",
    key: "option",
    label: "Topic Name*",
    type: "text",
    placeHolder: "Select topic",
    // option: ["Topic Name", "Driving", "Walking"],
  },
  {
    name: "name",
    imageUrl: "imageUrl",

    label: "Name*",
    type: "text",
    placeHolder: "Name",
  },
  {
    name: "imageUrl",
    imageUrl: "imageUrl",
    accept: "image",
    label: "Image*",
    type: "file",
    placeHolder: "Upload image url",
  },
  {
    name: "profileUrl",
    profileUrl: "profileUrl",
    accept: "image",
    label: "Profile Image*",
    type: "file",
    placeHolder: "Upload image url",
  },
  {
    name: "title",
    title: "title",
    type: "text",
    label: "Title*",
    placeHolder: "Enter your title",
  },
  {
    name: "description",
    description: "description",
    type: "text",
    label: "Description*",
    placeHolder: "Enter your description",
  },
];
export const articleEditInput = [
  {
    name: "topicId",
    key: "option",
    label: "Topic Name*",
    type: "text",
    placeHolder: "Select topic",
    // option: ["Topic Name", "Driving", "Walking"],
  },
  {
    name: "name",
    // imageUrl: "imageUrl",
    label: "Name*",
    type: "text",
    placeHolder: "Name",
  },
  {
    name: "imageUrl",
    imageUrl: "imageUrl",
    accept: "image",
    label: "Image*",
    type: "file",
    placeHolder: "Upload image url",
  },
  {
    name: "profileUrl",
    profileUrl: "profileUrl",
    accept: "image",
    label: "Profile Image*",
    type: "file",
    placeHolder: "Upload image url",
  },
  {
    name: "title",
    title: "title",
    type: "text",
    label: "Title*",
    placeHolder: "Enter your title",
  },
  {
    name: "description",
    description: "description",
    type: "text",
    label: "Description*",
    placeHolder: "Enter your description",
  },
];
export const SaftyInfo = [
  {
    type: 1,
    label: "Safety & Etiquette*",
  },
  {
    type: 2,
    label: "Budgeting, Fees & Reservation*",
  },
  {
    type: 3,
    label: "Time & Schedule*",
  },
  {
    type: 4,
    label: "Suggested Packing List*",
  },
];

export const addTopic = [
  {
    name: "name",
    type: "text",
    label: "Name*",
  },
  {
    name: "imageUrl",
    type: "file",
    accept: "image",
    label: "Add Image*",
  },
];
export const forgotPassword = [
  {
    type: "text",
    name: "email",
    text: "email",
    label: "Email*",
    placeholder: "Enter your email",
  },
];
export const resetpassword = [
  {
    type: "password",
    name: "newPassword",
    text: "text",
    label: "New Password*",
    placeholder: "New Password",
  },
  {
    type: "password",
    name: "confirmPassword",
    text: "text",
    label: "Confirm Password*",
    placeholder: "Confirm Password",
  },
];

export const createUserInput = [
  {
    name: "name",
    label: "Name",
    type: "text",
    placeHolder: "Enter user name",
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    placeHolder: "Email",
    value: "",
  },
  {
    name: "phone",
    label: "Phone Number",
    type: "text",
    placeHolder: "Phone Number",
    value: "",
  },
  {
    name: "cartDetails",
    key: "option",
    key1: "tourId",
    label: "Add Tour*",
  },
];

export const AddTourInput = [
  {
    name: "cartDetails",
    key: "option",
    key1: "tourId",
    label: "Add Tour*",
  },
];

export const STORAGE_PATH = "/mnt/trippy-storage";